import { useEffect, useState } from "react";
import React from 'react';
import LazyLoad from 'react-lazyload';
import { useNavigate, Link } from "react-router-dom";
import { getGalleryInner } from "../Service/Api";
import Header from '../Component/Header';
import Footer from'../Component/Footer';

const PhotoGallery = () => {
  useEffect(() => {
    // Set the page title
    document.title = 'Photo Gallery - Presentation Convent School Delhi';  
  }, []);

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filterData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState("All");
  const [selectedMonth, setSelectedMonth] = useState("All");
  const [monthOptions, setMonthOptions] = useState(["All"]);
  const [yearRanges, setYearRanges] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Fetch the gallery data and year/month options
  useEffect(() => {
    const fetchData = async () => {
      const galleryData1 = await getGalleryInner();
      const galleryData = galleryData1.filter(item => item.category === 'PhotoGallery');
      setData(galleryData);

      const yearsSet = new Set();
      const monthsSet = new Set();

      galleryData.forEach(item => {
        if (item.date) {
          const date = new Date(item.date);
          const getmonth = date.getMonth();
          yearsSet.add(date.getFullYear());
          monthsSet.add(getmonth);
        }
      });

      const uniqueYearRanges = Array.from(yearsSet).sort((a, b) => b - a);
      setYearRanges(uniqueYearRanges);

      const monthOptions = Array.from(monthsSet).sort((a, b) => a - b).map(month => monthNames[month]);
      setMonthOptions(["All", ...monthOptions]);

      console.log("Year Ranges:", uniqueYearRanges);
      console.log("Month Options:", monthOptions);
    };

    fetchData();
  }, []);

  // Update filtered data based on selected filters (Year, Month, Search Query)
  useEffect(() => {
    let filteredData = data;

    // If selectedYear is not "All" or selectedMonth is not "All", filter by those values
    if (selectedYear !== "All" || selectedMonth !== "All") {
      filteredData = filteredData.filter(item => {
        const itemDate = item.date?.trim();
        if (itemDate) {
          const date = new Date(itemDate);
          const year = date.getFullYear();
          const month = date.getMonth();
          const isYearMatch = selectedYear === "All" || year === parseInt(selectedYear);
          const isMonthMatch = selectedMonth === "All" || monthNames[month] === selectedMonth;
          return isYearMatch && isMonthMatch;
        }
        return false;
      });
    }

    // If search query exists, filter by title
    if (searchQuery) {
      filteredData = filteredData.filter(item => item.title.toLowerCase().includes(searchQuery.toLowerCase()));
    }

    // Sort filtered data by date in descending order
    filteredData.sort((a, b) => new Date(b.date) - new Date(a.date));

    setFilteredData(filteredData);
  }, [selectedYear, selectedMonth, searchQuery, data]);

  const handleModal = id => {
    navigate(`/SubGallery?id=${id}`);
  };

  const emptyArray = [
    { id: 1, img: "https://webapi.entab.info/api/image/PCSD/public/Images/gal1.jpg" },
    { id: 2, img: "https://webapi.entab.info/api/image/PCSD/public/Images/gal1.jpg" }, 
  ];

  return (
    <>
      <Header />
      <div className="innerslide">
        <ul className="breadcrumb">
          <li><Link to="/"> Home</Link> </li>
          <li> Gallery</li>
          <li>Photo Gallery</li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Photo Gallery</h1>
            </div>
          </div>
          <div className="row tabs-dynamic">
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="count-val">
                <p>Total Categories: {filterData.length}</p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="month-selection">
                <select
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  className="yearSelection">
                  <option value="All">All</option>
                  {yearRanges.map((yr) => (
                    <option key={yr} value={yr}>{yr}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="month-selection">
                <select
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                  className="monthSelect">
                  {monthOptions.map((month, index) => (
                    <option key={index} value={month}>{month}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="searchBox">
                <input
                  type="text"
                  id="myInput"
                  name="name"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search Here.."
                />
              </div>
            </div>
          </div>
          <div className="row years year2024 showYear">
            {filterData.length > 0 ? (
              filterData.map((item, index) => {
                const date = new Date(item.date);
                const day = date.getDate();
                const month = monthNames[date.getMonth()];
                return (
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 galleryCount" key={index}>
                    <div className="gallery-inner">
                      <div className="gallery-blks">
                        <div className="backDiv"></div>
                        <LazyLoad>
                          <img src={`https://webapi.entab.info/api/image/${item?.attachments[0]}`} className="img-fluid" alt="PCS Delhi" />
                        </LazyLoad>
                      </div>
                      <div className="gallery-content">
                        <div className="gallery-date">
                          <h6>{day} <span>{month}</span></h6>
                          <div className="gall-num">
                            <h5><span>Number Of Photos :</span>{item?.attachments.length}</h5>
                          </div>
                        </div>
                        <div className="gallery-title">
                          <h3>{item.title}</h3>
                        </div>
                        <a onClick={() => navigate(`/SubGallery?id=${item._id}`)} className="view-more">View More <i className="bi bi-arrow-right"></i></a>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              emptyArray.map((data, index) => (
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 galleryCount " key={index}>
                  <div className="gallery-inner">
                    <div className="gallery-blks">
                      <div className="backDiv"></div>
                      <img src={data.img} className="img-fluid" alt="PCS Delhi" />
                    </div>
                    <div className="gallery-content">
                      <div className="gallery-date">
                        <h6>DD<span> Mon </span></h6>
                        <div className="gall-num">
                          <h5><span>Number Of Photos :</span>NO</h5>
                        </div>
                      </div>
                      <div className="gallery-title">
                        <h3>Gallery Title</h3>
                      </div>
                      <Link to="/SubGallery" className="view-more">View More <i className="bi bi-arrow-right"></i></Link>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PhotoGallery;
