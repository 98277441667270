import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import { getTopper } from '../Service/Api';
import LazyLoad from 'react-lazyload';
const TopperXII = () => {
  const [classXIIData, setClassXIIData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const topperData = await getTopper();  
      const classXII = topperData.filter((item) => item.class === 'XII');
      setClassXIIData(classXII);  
    };
    fetchData();
  }, []);

  const emptyArray = [
    { name: "Student Name", division: "%",stream: 'Stream' },
    { name: "Student Name", division: "%",stream: 'Stream' },
    { name: "Student Name", division: "%",stream: 'Stream' },
    { name: "Student Name", division: "%",stream: 'Stream' }
  ];

  const settings = {
    autoplay: true,
    infinite: true, 
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false, 
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <> 
      <div className="row">
        {classXIIData.length > 0 ? (
          <Slider {...settings}> 
            {classXIIData.map((item1, index) => (
              <div className="item" key={index}>
                <div className="topperdiv">
                  <div className="topperimages">
                  <LazyLoad> <img src={`https://webapi.entab.info/api/image/${item1?.attachments}`} alt="Topper" className="img-fluid"/></LazyLoad> 
                  </div>
                  <img src="https://webapi.entab.info/api/image/PCSD/public/Images/tooperpicicn.png" alt="Icon" className="img-fluid tooperpicicn"/>
                  <div className="topperdesc">
                    <p className="per">{item1?.division}</p>
                    <p className="name">{item1?.name} <span> {item1?.stream} </span></p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <Slider {...settings}> 
            {emptyArray.map((data, index) => (
              <div className="item" key={index}>
                <div className="topperdiv">
                  <div className="topperimages">
                    <img src="https://webapi.entab.info/api/image/PCSD/public/Images/defaultImage.jpg" alt="Default" className="img-fluid"/>
                  </div>
                  <img src="https://webapi.entab.info/api/image/PCSD/public/Images/tooperpicicn.png" alt="Icon" className="img-fluid tooperpicicn"/>
                  <div className="topperdesc">
                    <p className="per">{data.division}</p>
                    <p className="name">{data.name} <span> {data.stream} </span></p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </>
  );
}

export default TopperXII;
